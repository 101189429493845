window.jQuery = window.$ = require('jquery');
require('slick-carousel');

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/dataLayer'));
    processInclude(require('base/components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/header'));
    processInclude(require('base/components/footer'));
    processInclude(require('base/components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
});

require('base/thirdParty/bootstrap');
require('./components/spinner');

$(window).on("load", function () {
    console.log("load_____1");
    var url = window.location.href;
    if (url.indexOf("#") !== -1) {
        var anchorId = url.split("#")[1];
        var anchor = $("#" + anchorId);
        if (anchor.length) {
            $("html, body").animate({
                    scrollTop: anchor.offset().top - 64,
                },
                1000
            );
        }
    }
});